<template>
  <div class="mh-100 venue-page bg-page-dark">
    <portal to="header-top">
      <b-btn
        size="sm"
        variant="outline-primary"
        class="bg-white"
        @click="goBack()"
      >
        <font-awesome-icon :icon="backIcon" fixed-width />
      </b-btn>
    </portal>
    <b-container
      v-if="!$apollo.loading && venue"
      v-touch:swipe.top="onSwipeTop"
      v-touch-options="{ swipeTolerance: 30, touchHoldTolerance: 500 }"
      class="bg-white border border-gray position-relative p-0 swiper"
    >
      <div
        class="d-flex p-2 w-100"
        style="z-index: 10"
        :class="{ 'position-absolute': venue.topMediaObject.length }"
      ></div>
      <my-carousel
        :media="venue.topMediaObject"
        @onImageClick="(media, i) => setFullScreenMedia(media, i)"
      >
      </my-carousel>

      <b-container class="my-4 p-0">
        <div class="p-2">
          <div class="d-flex flex-column">
            <h2 class="h2 text-primary caption text-uppercase">
              {{ venue.name }}
            </h2>
          </div>

          <b-row no-gutters class="">
            <b-col cols="6">
              <div
                v-if="venue.open"
                class="align-self-start text-primary text-uppercase font-weight-demi my-2"
              >
                Ouvert aujourd'hui
              </div>
              <small class="display font-weight-normal location text d-flex">
                <font-awesome-icon icon="map-marker-alt" />
                <div class="px-2">
                  {{ venue.address1 }} <br />
                  <template v-if="venue.address2">
                    {{ venue.address2 }} <br />
                  </template>
                  {{ venue.zipcode }}
                  {{ venue.city }}
                  {{ venue.distance }}
                </div>
              </small>
            </b-col>
            <b-col cols="6" class="d-flex flex-column">
              <div class="d-flex justify-content-end">
                <div class="text-center mt-auto position-relative">
                  <b-img-lazy
                    v-if="venue.badge"
                    :alt="venue.name"
                    :src="venue.badge"
                    class="venue-badge"
                  />
                </div>
                <like-btn :venue="venue" class="ml-0" :auto="autoLike" />
                <bookmark-btn :venue="venue" :auto="autoBookmark" />
                <share-btn
                  :venue="venue"
                  class="flat text-primary"
                  :auto="autoShare"
                />
              </div>
            </b-col>
          </b-row>
        </div>
        <mdj-deal
          v-if="venue.activeDeals.length"
          :advertiser="advertiserDay"
          :deal="venue.activeDeals[0]"
          view="details"
          :auto="autoDeal"
          @click="deal()"
        />
        <div
          v-if="venue.activeMenus && venue.activeMenus.length"
          class="p-3 menu-wrapper"
        >
          <div class="px-3">
            <div
              class="bg-primary inner text-white text-uppercase text-center py-2 heading"
            >
              Menu du {{ venue.activeMenus[0].publishedAt.localeFormat }}
            </div>
          </div>
          <b-card
            no-body
            class="bg-white shadow-lg py-3 inner border border-bottom-0"
          >
            <b-card-body class="p-2 overflow-hidden">
              <mdj-menu :menu="venue.activeMenus[0]" :search="search" />
            </b-card-body>
          </b-card>
        </div>
        <div
          v-if="venue.permanentMenus.length"
          class="d-flex justify-content-center"
        >
          <btn-with-icon
            v-b-modal.menus-modal
            type="submit "
            class="mt-3 mb-4 btn-permanent-menu"
            :icon="['fas', 'utensils']"
            variant="primary"
            :only-icon="false"
            pill
          >
            Voir la carte des plats et les menus
          </btn-with-icon>
          <b-modal
            id="menus-modal"
            scrollable
            size="xl"
            hide-footer
            title="Autres menus et carte"
          >
            <div
              v-touch:swipe.top="onSwipeTopMenus"
              v-touch:swipe.bottom="onSwipeBottomMenus"
              v-touch-options="{ swipeTolerance: 80, touchHoldTolerance: 500 }"
            >
              <mdj-permanent-menu
                v-for="permanentMenu in venue.permanentMenus"
                :key="permanentMenu.id"
                :menu="permanentMenu"
                :search="search"
              />
            </div>
          </b-modal>
        </div>

        <div v-if="venue.lastPublication" class="mb-3 publication">
          <h3 class="p-3 h6 caption text-dark">Actualités</h3>
          <mdj-publication :publication="venue.lastPublication" />

          <b-link
            v-b-modal.publication-modal
            class="d-block text-center p-3 border-top d-flex align-items-center justify-content-center"
          >
            <font-awesome-icon
              :icon="['fas', 'newspaper']"
              size="2x"
              class="text-white mr-2"
            />voir toutes les actualités
          </b-link>
          <b-modal
            id="publication-modal"
            scrollable
            size="xl"
            hide-footer
            title="Toutes les actualités"
          >
            <mdj-publication
              v-for="publication in venue.publications"
              :key="publication.id"
              class="border-bottom"
              :publication="publication"
            />
          </b-modal>
        </div>

        <b-row v-if="venue.description" class="mt-4 description">
          <b-col cols="8" offset="2">
            <p v-html="venue.description"></p>
          </b-col>
        </b-row>
        <div v-if="venue.toTry.length" class="px-3">
          <h3 class="h6 caption text-dark">Nos spécialités</h3>
          <p v-for="(dish, i) in venue.toTry" :key="i" class="text mb-1">
            <span v-html="matchedDish(dish)"></span>
          </p>
        </div>
        <div v-if="venue.influencer" class="px-3 mt-3">
          <h3 class="h6 caption text-dark">
            <a :href="venue.influencer.link" target="_blank">
              Voir l'article sur {{ venue.influencer.name }}</a
            >
          </h3>
        </div>
      </b-container>
      <my-carousel
      class="mt-4"
        :media="venue.mediaObject"
        @onImageClick="(media, i) => setFullScreenMedia(media, i)"
      >
      </my-carousel>
      <div v-for="(information, i) in informations" :key="i" class="px-3 pb-4">
        <h3 class="h6 caption text-dark">
          {{ i }}
        </h3>
        <ul class="row no-gutters p-0 mb-0">
          <li v-for="(value, j) in information" :key="j">
            <h6>
              <b-badge variant="primary" class="mr-2">{{
                value.allowedInformationValue.caption
              }}</b-badge>
            </h6>
          </li>
        </ul>
      </div>
      <div class="d-flex justify-content-center">
        <div
          v-if="venue.openingHours && venue.openingHours.length"
          class="hours p-3 w-100"
        >
          <h3 class="h6 caption text-dark text-center">Horaires d'ouverture</h3>
          <b-row
            v-for="(day, i) in days"
            :key="i"
            class="text-primary"
            no-gutters
            :class="i === 0 ? 'font-weight-demi' : ''"
          >
            <b-col cols="6" class="text-right pr-1">
              {{ day.name }}
            </b-col>
            <b-col cols="6" class="pl-1">
              <template v-if="Array.isArray(day.hours)">
                <div
                  v-for="(hour, j) in day.hours"
                  :key="j"
                  style="width: 100px"
                  class="d-inline-block"
                >
                  {{ hour }}
                </div>
              </template>
              <span v-else>{{ day.hours }}</span>
            </b-col>
          </b-row>
        </div>
      </div>
      
      <div
        class="position-relative map-wrapper"
        @click="$bvModal.show('location-modal')"
      >
        <h3 class="h6 caption text-dark text-center">
        Nous trouver<br>(cliquez pour lancer une navigation)
      </h3>
      </div>
     <!-- 
      <h3 class="h6 caption text-dark text-center">
        Nous trouver (cliquez pour lancer une navigation)
      </h3>
      <div
        class="position-relative map-wrapper"
        @click="$bvModal.show('location-modal')"
      >
        <static-map
          class="w-100"
          google-api-key="AIzaSyA5NfbhYxd-QsrRoJtZkr_Ts7Gv9FF0gP8"
          :markers="markers"
          :zoom="15"
          :center="center"
        />
      </div> -->
      <b-modal id="location-modal" size="xl" hide-footer hide-header centered>
        <btn-with-icon
          v-for="(link, i) in drivings"
          :key="i"
          variant="primary"
          :icon="link.icon"
          :href="link.href"
          target="_blank"
          v-bind="$attrs"
          :only-icon="false"
          class="rounded-pill py-1 text-uppercase my-2"
        >
          {{ link.name }}
        </btn-with-icon>
      </b-modal>
      <div class="action-wrapper">
        <b-container>
          <b-row class="my-3">
            <b-col v-if="venue.shopEnabled">
              <shop-btn
                :venue="venue"
                :href="venue.shopUrl"
                :icon="false"
                class="btn-block"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <call-btn
                :venue="venue"
                :icon="false"
                class="btn-block"
                :auto="autoCall"
              />
            </b-col>
            <b-col v-if="venue.onlineReservation">
              <book-btn
                block
                :venue="venue"
                :icon="false"
                :auto="autoBook"
                :at="$route.query.date"
                :time="$route.query.time"
                class="btn-block"
              />
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-container>
    <fit-height-loader
      v-else-if="$apollo.loading"
      class="position-absolute"
      show
      style="z-index: 100; background-color: rgba(255, 255, 255, 1)"
    />
    <div
      v-else-if="!$apollo.loading && !venue"
      class="bg-white border border-gray position-relative p-0 h-100 d-flex flex-column justify-content-center align-items-center"
    >
      <p>La page demandée n'existe pas.</p>
      <b-btn :to="{ name: 'list' }" variant="primary">
        Retour à la liste
      </b-btn>
    </div>
  </div>
</template>
<script>
import ADVERTISER_DAY from "@/graphql/AdvertiserDay.gql"
import VENUE from "@/graphql/Venue.gql"
import MDJMenu from "@/components/Menu"
import MyCarousel from "@/components/MyCarousel"
import MDJPublication from "@/components/Publication"
import MDJDeal from "@/components/Deal"
import MDJPermanentMenu from "@/components/PermanentMenu"
import informations from "@/mixins/informations"
import _ from "lodash"
import StaticMap from "vue-static-map"
import moment from "moment-with-locales-es6"
import FitHeightLoader from "@/components/FitHeightLoader"
import { reservationHighlight } from "@/utils"
import {
  BookBtn,
  BookmarkBtn,
  LikeBtn,
  ShareBtn,
  CallBtn,
  ShopBtn,
  VoucherBtn
} from "@/components/buttons"

export default {
  components: {
    BookBtn,
    BookmarkBtn,
    LikeBtn,
    ShareBtn,
    CallBtn,
    VoucherBtn,
    "mdj-menu": MDJMenu,
    "mdj-publication": MDJPublication,
    StaticMap,
    "mdj-deal": MDJDeal,
    "mdj-permanent-menu": MDJPermanentMenu,
    ShopBtn,
    FitHeightLoader,
    MyCarousel
  },
  mixins: [informations],
  data() {
    return {
      mapOptions: {
        gestureHandling: "cooperative"
      },
      autoCall:
        this.$route.query.action === "call" ||
        this.$route.params.action === "call",
      autoShare:
        this.$route.query.action === "share" ||
        this.$route.params.action === "share",
      autoLike:
        this.$route.query.action === "like" ||
        this.$route.params.action === "like",
      autoBookmark:
        this.$route.query.action === "bookmark" ||
        this.$route.params.action === "bookmark",
      autoDeal:
        this.$route.query.action === "deal" ||
        this.$route.params.action === "deal",
      autoBook:
        this.$route.query.action === "book" ||
        this.$route.params.action === "book",
      prevRoute: null,
      search: this.$route.query.search,
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  mounted() {},
  computed: {
    drivings: function () {
      return [
        {
          name: "Waze",
          href: `https://www.waze.com/ul?ll=${this.venue.location.coordinates.lat},${this.venue.location.coordinates.lng}&navigate=yes&zoom=17`,
          icon: ["fab", "waze"]
        },
        {
          name: "Google Maps",
          href: `https://www.google.com/maps/place/${this.venue.location.coordinates.lat},${this.venue.location.coordinates.lng}`,
          icon: ["fab", "google"]
        },
        {
          name: "Apple Plan",
          href: `http://maps.apple.com/?ll=${this.venue.location.coordinates.lat},${this.venue.location.coordinates.lng}&q=${this.venue.name}`,
          icon: ["fab", "apple"]
        }
      ]
    },
    informations: function () {
      return _.chain(this.venue.informations)
        .filter(i => {
          return i.allowedInformationValue
        })
        .sortBy(i => {
          return i.allowedInformationValue.caption
        })
        .groupBy("information.description")
        .value()
    },
    markers: function () {
      return [
        {
          lat: this.venue.location.coordinates.lat,
          lng: this.venue.location.coordinates.lng
        }
      ]
    },
    center: function () {
      return (
        this.venue.location.coordinates.lat +
        "," +
        this.venue.location.coordinates.lng
      )
    },
    preparedOpeningHours: function () {
      return _.chain(this.venue.openingHours)
        .orderBy(["openAt"], ["asc"])
        .groupBy("day")
        .mapValues(hours => {
          return _.map(hours, hour => {
            return hour.openAt + "-" + hour.closeAt
          })
        })
        .value()
    },
    startOfWeek: function () {
      return moment()
    },
    endOfWeek: function () {
      return moment().add(7, "days")
    },
    days: function () {
      let days = []
      let current = this.startOfWeek.clone()
      while (current < this.endOfWeek) {
        days.push({
          name: current.format("dddd"),
          hours: this.preparedOpeningHours[current.format("d")]
            ? this.preparedOpeningHours[current.format("d")]
            : "Fermé"
        })
        current.add(1, "days")
      }
      return days
    },
    backIcon: function() {
      if (!this.prevRoute || (this.prevRoute && !this.prevRoute.name)) {
        return 'home'
      } else {
        return 'arrow-left'
      }
    }
  },
  created() {
    moment.locale("fr")
  },
  apollo: {
    venue: {
      query: VENUE,
      variables() {
        return {
          id: parseInt(this.$route.params.id, 10) || 0
        }
      },
      result() {
        this.$nextTick(() => {
          if (this.$route.query.action === "permanent-menu") {
            this.$bvModal.show("menus-modal")
          }
        })
      }
    },
    advertiserDay: {
      query: ADVERTISER_DAY
    }
  },
  methods: {
    setFullScreenMedia(media, index) {
      this.$store.commit("fullScreenMedia", { media, index })
    },
    deal() {
      this.$store.commit("dealVenue", this.venue)
    },
    swipe() {
      this.goBack()
    },
    goBack() {
      this.$apollo.queries.venue.skip = true
      if (!this.prevRoute || (this.prevRoute && !this.prevRoute.name)) {
        this.$router.push({ name: "list" })
      } else {
        this.$router.back()
      }
    },
    matchedDish(dish) {
      return reservationHighlight(this.search, dish)
    },
    onSwipeTop() {
      let elt = document.querySelector(".venue-page")
      if (elt.scrollTop + elt.offsetHeight >= elt.scrollHeight) {
        this.goBack()
      }
    },
    onSwipeTopMenus() {
      let elt = document.querySelector("#menus-modal___BV_modal_body_")
      if (elt.scrollTop + elt.offsetHeight >= elt.scrollHeight) {
        this.$bvModal.hide("menus-modal")
      }
    },
    onSwipeBottomMenus() {
      let elt = document.querySelector("#menus-modal___BV_modal_body_")
      if (elt.scrollTop <= 0) {
        this.$bvModal.hide("menus-modal")
      }
    }
  }
}
</script>

<style lang="scss" scoped>
ul {
  li {
    list-style-type: none;
    text-emphasis: wrap;
    font-size: 12px;
  }
}
.action-wrapper {
  position: sticky;
  bottom: 0;
  background-color: #fff;
  padding: 10px 0;
}
.venue-badge {
  height: 50px;
}
</style>
